.slider {
  text-align: center;
  background-color: #e6edf3;
  overflow: hidden;
  padding: 15px 0;
  /* margin: 10px 0; */
}

@media (max-width: 767px) {
  .slider {
    padding: 15px 0;
  }
}

.slider h1 {
  display: inline;
  color: var(--main-color);
  font-size: 3em !important;
  position: relative;
}

@media (max-width: 767px) {
  .slider h1 {
    font-size: 2em !important;
  }
}

.slider h1::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 6px;
  background-color: var(--main-color);
  bottom: 7px;
  left: 0;
}

.slider .slider_wrapper {
  margin-top: 40px;
  display: flex;
  animation: move linear 200s infinite;
}

.slider .slider_wrapper .slider_item {
  width: 100%;
  flex-shrink: 0;
  text-align: center;
  display: flex;
  margin: 0 10px;
}

.slider .slider_wrapper .slider_item img {
  height: 300px;
  border: 2px solid white;
  margin: 0 50px;
}

@media (max-width: 767px) {
  .slider .slider_wrapper .slider_item img {
    height: 100px;
    margin: 0 10px;
  }
}

@keyframes move {
  0% {
    transform: translate3d(2500%, 0, 0);
  }
  100% {
    transform: translate3d(0%, 0, 0);
  }
}

.slider .slider_wrapper:hover {
  animation-play-state: paused;
}
