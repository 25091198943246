.section .container {
  width: 80%;
}
.section .content {
  text-align: center;
}

.section h2 {
  padding: 20px;
  font-size: 40px;
  color: var(--main-color);
}

.section .box {
  margin: auto;
  width: 50%;
  padding: 15px;
  background-color: white;
  box-shadow: 0px 12px 20px 0px rgb(0 0 0 / 13%),
    0px 2px 4px 0px rgb(0 0 0 / 12%);
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .section .box {
    margin: 0;
    width: 100%;
  }
}
.section .box .image {
  position: relative;
  overflow: hidden;
}
.section .box .image::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255 255 255 / 20%);
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 2;
}
.section .box .image:hover::before {
  animation: flashing 0.7s;
}
.section .box img {
  max-width: 100%;
  transition: var(--main-transition);
}
.section .box .image:hover img {
  transform: rotate(5deg) scale(1.1);
}

.section p {
  margin-bottom: 15px;
  font-size: 25px;
}

@media (max-width: 767px) {
  .section p {
    padding: 0;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
