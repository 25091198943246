.about_hospital {
  text-align: center;
  padding-top: var(--main-padding-top);
  padding-bottom: var(--main-padding-bottom);
  position: relative;
  background-color: var(--section-background);
}

.about_hospital_header {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 30px;
  color: var(--main-color);
  text-decoration: underline;
  margin: 50px 100px;
}

@media (max-width: 767px) {
  .about_hospital_header {
    font-size: 19px;
  }
}

.about_hospital .about_box {
  text-align: center;
  border-bottom: 1px solid var(--main-color);
  padding-bottom: 45px;
}
.about_hospital .about_box .about_video {
  display: block;
  margin: auto;
}

@media (max-width: 767px) {
  .about_hospital .about_box .about_video {
    display: none;
  }
}

.about_hospital .about_box p {
  font-size: 20px;
  margin: 50px 100px;
}

@media (max-width: 767px) {
  .about_hospital .about_box p {
    font-size: 18px;
    margin: 50px 50px;
  }
}

.about_hospital .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 40px;
}

@media (max-width: 767px) {
  .about_hospital .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    gap: 40px;
  }
}

.about_hospital .box {
  text-align: center;
  padding: 15px;
  background-color: white;
  box-shadow: 0px 12px 20px 0px rgb(0 0 0 / 13%),
    0px 2px 4px 0px rgb(0 0 0 / 12%);
}
.about_hospital .box .image {
  position: relative;
  overflow: hidden;
}
.about_hospital .box .image::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255 255 255 / 20%);
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 2;
}
.about_hospital .box .image:hover::before {
  animation: flashing 0.7s;
}
.about_hospital .box img {
  width: 100%;
  transition: var(--main-transition);
}
.about_hospital .box .image:hover img {
  transform: rotate(5deg) scale(1.1);
}
