.landing {
  position: relative;
  display: flex;
  justify-content: center;
  height: calc(100vh - 153px);
  overflow: hidden;
}

@media (max-width: 767px) {
  .landing {
    height: 50vh;
  }
}

@media (max-width: 360px) {
  .landing {
    height: 80vh;
  }
}

.overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.landing .text {
  position: absolute;
  top: 100px;
  color: white;
  font-size: 2rem;
  text-align: center;
  z-index: 2;
}
.landing .text h1,
.landing .text h3,
.landing .text p {
  margin-bottom: 20px;
}
.landing .text p {
  font-size: 50px;
}

@media (max-width: 767px) {
  .landing .text p {
    font-size: 25px;
  }
}

@media (max-width: 767px) {
  .landing .text {
    padding: 0;
    font-size: 1rem;
    top: 10px;
  }
}

.landing .image,
.landing .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.landing button {
  position: absolute;
  z-index: 2;
  bottom: 25%;
  padding: 10px;
  background-color: transparent;
  color: white;
  border: 3px solid white;
  border-radius: 10px;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
  transition: var(--main-transition);
}

.landing button:hover {
  background-color: white;
  color: var(--main-color);
}

@media (max-width: 767px) {
  .landing button {
    bottom: 15%;
  }
}
