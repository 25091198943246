.header {
  background-color: white;
  position: relative;
  -webkit-box-shadow: 0 0 10px #ddd;
  -moz-box-shadow: 0 0 10px #ddd;
  box-shadow: 0 0 10px #ddd;
}
.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

@media (max-width: 767px) {
  .header .container {
    justify-content: center;
  }
}
.header .logo {
  height: 100px;
}
@media (max-width: 767px) {
  .header .logo {
    height: 100px;
    margin: auto;
  }
}

.header .main-nav {
  display: flex;
}
@media (max-width: 767px) {
  .header .main-nav {
    margin: auto;
  }
}
.header .main-nav > li:hover .mega-menu {
  opacity: 1;
  z-index: 100;
  top: calc(100% + 1px);
}
.header .main-nav > li > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  position: relative;
  color: var(--main-color);
  padding: 0 30px;
  overflow: hidden;
  font-size: 18px;
  font-weight: bold;
  transition: var(--main-transition);
}
@media (max-width: 767px) {
  .header .main-nav > li > a {
    padding: 10px;
    font-size: 10px;
    height: 40px;
  }
}
.header .main-nav > li > a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: var(--main-color);
  top: 0;
  left: -100%;
  transition: var(--main-transition);
}
.header .main-nav > li > a:hover {
  color: var(--main-color);
  background-color: #fafafa;
}
.header .main-nav > li > a:hover::before {
  left: 0;
}

.header .mega-menu {
  position: absolute;
  width: 100%;
  left: 0;
  padding: 30px;
  background-color: white;
  border-bottom: 3px solid var(--main-color);
  z-index: -1;
  display: flex;
  gap: 40px;
  top: calc(100% + 50px);
  opacity: 0;
  transition: top var(--main-transition), opacity var(--main-transition);
}
@media (max-width: 767px) {
  .header .mega-menu {
    flex-direction: column;
    gap: 0;
    padding: 5px;
  }
}

.header .gallery-menu .links {
  text-align: center;
}

.header .mega-menu .image img {
  max-width: 100%;
}
@media (max-width: 991px) {
  .header .mega-menu .image {
    display: none;
  }
}
.header .mega-menu .links {
  min-width: 250px;
  flex: 1;
}
.header .mega-menu .links li {
  position: relative;
}
.header .mega-menu .links li:not(:last-child) {
  border-bottom: 1px solid #e9e6e6;
}
@media (max-width: 767px) {
  .header .mega-menu .links:first-of-type li:last-child {
    border-bottom: 1px solid #e9e6e6;
  }
}
.header .mega-menu .links li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #fafafa;
  z-index: -1;
  transition: var(--main-transition);
}
.header .mega-menu .links li:hover::before {
  width: 100%;
}
.header .mega-menu .links li a {
  color: var(--main-color);
  padding: 15px;
  display: block;
  font-size: 18px;
  font-weight: bold;
}
.header .mega-menu .links li a i {
  margin-right: 10px;
}
