.gallery {
  text-align: center;
  padding-top: var(--main-padding-top);
  padding-bottom: var(--main-padding-bottom);
  position: relative;
  background-color: var(--section-background);
}

.gallery .gallery-header {
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .gallery .gallery-header {
    flex-direction: column;
  }
}

.gallery .header-title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 30px;
  color: var(--main-color);
  text-decoration: underline;
  margin: 50px 100px;
}

@media (max-width: 767px) {
  .gallery .gallery-header .header-title {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.gallery .gallery-header .header-dropdown {
  position: relative;
}

.gallery .gallery-header .secondary-title {
  margin: 0 100px;
  border: 2px solid var(--main-color);
  padding: 10px 20px;
  font-size: 30px;
  color: var(--main-color);
  width: -moz-fit-content;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .gallery .gallery-header .secondary-title {
    font-size: 20px;
  }
}

.gallery .gallery-header .secondary-title svg {
  margin: 0 5px;
}

.gallery-header .header-dropdown:hover .header-dropdown-content {
  display: block;
}

.gallery-header .header-dropdown-content {
  display: none;
  position: absolute;
  left: 100px;
  top: 60px;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
}

.gallery-header .header-dropdown-content a {
  color: var(--main-color);
  font-size: 20px;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid var(--main-color);
  cursor: pointer;
}

.gallery-header .header-dropdown-content a:hover {
  background-color: var(--main-color);
  color: white;
}

.gallery .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 40px;
}
.gallery .box {
  text-align: center;
  padding: 5px;
  background-color: white;
  box-shadow: 0px 12px 20px 0px rgb(0 0 0 / 13%),
    0px 2px 4px 0px rgb(0 0 0 / 12%);
}
.gallery .box .image {
  position: relative;
  overflow: hidden;
}
.gallery .box .image::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255 255 255 / 20%);
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 2;
}
.gallery .box .image:hover::before {
  animation: flashing 0.7s;
}



.gallery .box img {
  width: 100%;
  transition: var(--main-transition);
}


@media (max-width: 767px) {
  .gallery .box img {
    width: 80%;
    transition: var(--main-transition);
  }

  .gallery .box {
    width: 70%;
  }

}

.gallery .box .image:hover img {
  transform: rotate(5deg) scale(1.1);
}
