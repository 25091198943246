.companies {
  text-align: center;
  overflow: hidden;
  padding: 15px 0;
  margin: 10px 0;
}

@media (max-width: 767px) {
  .companies {
    padding: 15px 0;
  }
}

.companies h1 {
  display: inline;
  color: var(--main-color);
  font-size: 2em !important;
  position: relative;
}

@media (max-width: 767px) {
  .companies h1 {
    font-size: 23px !important;
  }
  .companies h1::after {
    display: none;
  }
}

.companies h1::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 6px;
  background-color: var(--main-color);
  bottom: 0px;
  left: 0;
}

.companies .companies_wrapper {
  margin-top: 40px;
  display: flex;
  animation: movee linear 30s infinite;
}

.companies .companies_wrapper .companies_item {
  width: 100%;
  flex-shrink: 0;
  text-align: center;
  display: flex;
  margin: 0 10px;
}

.companies .companies_wrapper .companies_item img {
  height: 100px;
  border: 2px solid white;
  margin: 0 10px;
}

@media (max-width: 767px) {
  .companies .companies_wrapper .companies_item img {
    height: 100px;
  }
}

@keyframes movee {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

.companies .companies_wrapper:hover {
  animation-play-state: paused;
}
