* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --main-color: #034487;
  --main-color-alt: #2d3742;
  --main-transition: 0.3s;
  --main-padding-top: 100px;
  --main-padding-bottom: 100px;
  --section-background: #ececec;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
}

body:lang(ar) {
  font-family: "Cairo", sans-serif;
}

a {
  text-decoration: none;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

/* Small */
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

/* Medium */
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

/* Large */
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

/* .main-title {
    text-transform: uppercase;
    margin: 0 auto 80px;
    border: 2px solid black;
    padding: 10px 20px;
    font-size: 30px;
    width: fit-content;
    position: relative;
    z-index: 1;
} */
