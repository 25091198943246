
.topbar {
    background-color: #F6F6F6;
    position: relative;
    color: var(--main-color);
}

.topbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}

@media (max-width: 767px) {
    .topbar .container {
        justify-content: center;
    }
}

.topbar .social-icons {
    padding: 10px;
}

@media (max-width: 767px) {
    .social-icons {
        display: none;
    }
}

.topbar .social-icons .icon{
    cursor: pointer;
}



.topbar .phones{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 767px) {
    .phone-icon {
        display: none !important;
    }
}
.topbar .phones span {
    margin: auto 10px;
}

@media (max-width: 360px) {
    .topbar .phones span {
        margin: 0;
    }
}

.topbar .language {
    cursor: pointer;
}

@media (max-width: 767px) {
    .language-icon {
        display: none !important;
    }
}


.topbar .language:hover .languages-container {
    display: block;
}

.topbar .languages-container{
    display: none;
    position: absolute;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.topbar .languages-container a {
    display: block;
    padding: 12px 16px;
    text-decoration: none;
    font-size: 16px;
    transition: 0.3s;
    cursor:pointer;
    color: var(--main-color);
}
.topbar .languages-container a:hover {
    background-color: #f1f1f1
}