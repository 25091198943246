.footer {
  background: #0575e6; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #021b79,
    #0575e6
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #021b79,
    #0575e6
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  padding: 70px 0 0;
}
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
}
.footer .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 40px;
}
.footer .box h3 {
  color: white;
  font-size: 30px;
  margin: 0 0 20px;
}
.footer .box .social {
  display: flex;
}
@media (max-width: 767px) {
  .footer .box .social {
    justify-content: center;
  }
}
.footer .box .social li {
  margin-right: 10px;
}
.footer .box .social li a {
  background-color: #313131;
  color: #b9b9b9;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
  transition: var(--main-transition);
}
.footer .box .social .facebook:hover {
  background-color: #1877f2;
}
.footer .box .social .instagram:hover {
  background-color: #e1306c;
}
.footer .box .text {
  line-height: 2;
  color: #b9b9b9;
}
.footer .box .links li {
  padding: 15px 0;
  transition: var(--main-transition);
}
.footer .box .links li:not(:last-child) {
  border-bottom: 1px solid white;
}
.footer .box .links li:hover {
  padding-left: 10px;
}
.footer .box .links li:hover a {
  color: white;
}
.footer .box .links li a {
  color: white;
  transition: var(--main-transition);
}
.footer .box .line {
  display: flex;
  align-items: center;
  color: white;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .footer .box .line {
    flex-direction: column;
  }
}
.footer .box .line i {
  font-size: 25px;
  color: white;
  margin-right: 10px;
}
@media (max-width: 767px) {
  .footer .box .line i {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.footer .box .line .info {
  line-height: 1.7;
  flex: 1;
}
.footer .box .line .info span {
  display: block;
}
.footer .footer-gallery img {
  width: 78px;
  border: 3px solid white;
  margin: 2px;
}
.footer .copyright {
  padding: 25px 0;
  text-align: center;
  color: white;
  margin: 50px 0 0;
  border-top: 1px solid white;
}
