.image-modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

#toggleActive {
  display: block;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

.modal-content img {
  width: 100%;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.contact {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
  background-color: var(--main-color);
}

@media (max-width: 767px) {
  .contact {
    flex-direction: column;
    padding-top: 10px;
  }
}

.contact .map {
  flex: 1;
  text-align: center;
}

.contact .map .directions {
  color: white;
  border: 5px solid white;
  border-radius: 12px;
  padding: 10px;
}

@media (max-width: 767px) {
  .contact .map .directions {
    display: none;
  }
}

.contact .map .map-wrapper {
  width: 100%;
  height: 100vh;
  padding: 40px;
}

@media (max-width: 767px) {
  .contact .map .map-wrapper {
    padding: 15px;
    height: 50vh;
  }
}

.contact .form {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  background-image: url("../../../public/images/contact.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.contact .form h1 {
  color: white;
  font-size: 3em !important;
  position: relative;
  display: inline-block;
  margin-bottom: 50px;
}

.contact .form h1::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 6px;
  background-color: white;
  bottom: 5px;
  left: 0;
}

.contact .form .form-wrapper {
  width: 100%;
  height: 100vh;
  padding: 50px;
  text-align: center;
}

@media (max-width: 767px) {
  .contact .form .form-wrapper {
    padding: 30px;
  }
}

@media (max-width: 360px) {
  .contact .form .form-wrapper {
    height: 100%;
  }
}

.contact .form form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.contact .form .input {
  display: block;
  width: 80%;
  margin-bottom: 35px;
  padding: 15px;
  border: none;
  border-bottom: 1px solid #ccc;
  background-color: #f9f9f9;
  caret-color: var(--main-color);
  border-radius: 10px;
}

@media (max-width: 767px) {
  .contact .form .input {
    width: 100%;
  }
}

.contact .form textarea.input {
  resize: none;
  height: 200px;
}
.contact .form .input:focus {
  outline: none;
}
.contact .form [type="submit"] {
  display: block;
  width: 80%;
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  color: var(--main-color);
  font-weight: bold;
  font-size: 20px;
  border: none;
  cursor: pointer;
  transition: var(--main-transition);
}

@media (max-width: 767px) {
  .contact .form [type="submit"] {
    width: 100%;
  }
}
